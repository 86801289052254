.spinner-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
}

.blog-wrapper {
  white-space: pre-line;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17em, 1fr));
  grid-gap: 1.5em 0;
  justify-items: center;
}

.blog-post-wrapper {
  * {
    overflow-wrap: break-word;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    color: rgb(65, 77, 99);
    height: 100%;
    overflow-y: auto;
  }

  h1 {
    font-size: 2.5em;
    line-height: 1.2;
    margin: 1em 0;
  }

  h2 {
    font-size: 2em;
    line-height: 1.2;
    margin: 1em 0;
  }

  h3 {
    font-size: 1.5em;
    line-height: 1.2;
    margin: 1em 0;
  }

  h4 {
    font-size: 1.25em;
    margin: 1em 0;
  }

  h5,
  p,
  a,
  ul,
  ol,
  blockquote {
    font-size: 1em;
    margin-bottom: 0.5em;
  }

  h6 {
    font-size: 0.75em;
  }

  ol {
    margin: 0.75rem 0px;
    padding-left: 1rem;
  }

  p {
    line-height: 1.5;
    img {
      width: 100%;
      height: 400px;
      object-fit: contain;
      border-radius: 4px;
    }

    a {
      color: rgb(49, 49, 129);
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
        opacity: 0.6;
      }
    }
  }
}
