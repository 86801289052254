.spinner-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
}

.blog-wrapper {
  white-space: pre-line;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17em, 1fr));
  grid-gap: 1.5em 0;
  justify-items: center;
}

.blog-page-header {
  text-align: center;
  padding: 1em 0;
}
