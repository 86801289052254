.generator-wrapper {
  min-height: calc(100vh - 58px);
  white-space: pre-line;

  .generator-container {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .generator-text {
    input[type='range'] {
      height: 28px;
      -webkit-appearance: none;
      appearance: none;
      margin: 10px 0;
      width: 100%;
    }
    input[type='range']:focus {
      outline: none;
    }
    input[type='range']::-webkit-slider-runnable-track {
      width: 100%;
      height: 9px;
      cursor: pointer;
      transition: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: rgba(111, 92, 195, 0.7);
      border-radius: 10px;
      border: 0px solid #000000;
    }
    input[type='range']::-webkit-slider-thumb {
      box-shadow: 1px 1px 1px #000000;
      border: 1px solid #000000;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      background: #ffffff;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -6px;
    }
    input[type='range']:focus::-webkit-slider-runnable-track {
      background: rgba(111, 92, 195, 0.7);
    }
    input[type='range']::-moz-range-track {
      width: 100%;
      height: 9px;
      cursor: pointer;
      transition: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: rgba(111, 92, 195, 0.7);
      border-radius: 10px;
      border: 0px solid #000000;
    }
    input[type='range']::-moz-range-thumb {
      box-shadow: 1px 1px 1px #000000;
      border: 1px solid #000000;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      background: #ffffff;
      cursor: pointer;
    }
    input[type='range']::-ms-track {
      width: 100%;
      height: 9px;
      cursor: pointer;
      transition: 0.2s;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    input[type='range']::-ms-fill-lower {
      background: rgba(111, 92, 195, 0.7);
      border: 0px solid #000000;
      border-radius: 20px;
      box-shadow: 0px 0px 0px #000000;
    }
    input[type='range']::-ms-fill-upper {
      background: rgba(111, 92, 195, 0.7);
      border: 0px solid #000000;
      border-radius: 20px;
      box-shadow: 0px 0px 0px #000000;
    }
    input[type='range']::-ms-thumb {
      margin-top: 1px;
      box-shadow: 1px 1px 1px #000000;
      border: 1px solid #000000;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      background: #ffffff;
      cursor: pointer;
    }
    input[type='range']:focus::-ms-fill-lower {
      background: rgba(111, 92, 195, 0.7);
    }
    input[type='range']:focus::-ms-fill-upper {
      background: rgba(111, 92, 195, 0.7);
    }
  }

  .generated-password {
    height: 90px;
    text-align: center;
    font-size: 36px;
    background-color: #f1ecec;
  }

  .generator-options {
    background-color: #f1ecec;
    border-radius: 10px;
    border: 1px solid rgb(201, 206, 214);
    padding: 2rem;
  }

  .generator-options-buttons {
    button {
      width: 50%;
      height: 50px;
      svg {
        margin-right: 1rem;
        overflow: hidden;
        vertical-align: middle;
      }
    }
  }

  .generator-option {
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    transition: 0.3s linear;

    span {
      margin-left: 10px;
    }
  }

  .password-faq-wrapper {
    border-radius: 10px;
    overflow: hidden;
    margin-top: 1rem;

    h2 {
      padding: 20px;
    }

    p {
      text-align: justify;
    }
  }

  .password-page-content-wrapper {
    h2 {
      padding: 20px 20px 10px 20px;
    }

    p {
      text-align: justify;
      padding: 20px;
    }
  }
}

@media (max-width: 920px) {
  .generator-options-checkboxes {
    flex-direction: column;
    align-items: baseline;
  }

  .generator-options-buttons {
    flex-direction: column;
    align-items: center;

    button {
      width: 100% !important;
      margin: 0.5rem 0 !important;
      height: 60px !important;
    }
  }

  .generated-password {
    font-size: 24px !important;
  }
}
