.main-layout {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 5px;
  min-height: calc(100vh - 132px);
  margin-bottom: 20px;
}

.main-layout-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;

  .main-layout-header-logo {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
}

@media (max-width: 768px) {
  .main-layout {
    width: 100%;
    padding: 1rem 2rem;
  }
}
